<template>
  <v-container fluid>
    <crm-table-pageable-new
        :headers="headers"
        :data="data"
        :config="config"
        :loaded-options="options"
        :total-elements="totalElements"
        :total-pages="totalPages"
        @onChangeData="onChangeData"
        @handleActions="handleActions"
    ></crm-table-pageable-new>
    <supply-card v-if="dialogVisible"
                 :visible="dialogVisible"
                 :id="supply.id"
                 :products-count="supply.productsCount"
                 @closed="handleClose"/>
  </v-container>
</template>

<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import SupplyCard from '@/views/wms/operations/supplier-deliveries/SupplyCard.vue';

export default {
  name: 'index',
  components: {
    'crm-table-pageable-new': crmTablePageableNew,
    SupplyCard,
  },
  data() {
    return {
      headers: [
        {
          value: 'id', sortable: false, text: '#', type: 'defaultItem',
        },
        {
          value: 'supplier.name', sortable: false, text: 'Продавец', type: 'defaultItem',
        },
        {
          value: 'createdDate', sortable: true, text: 'Дата поступления заявки', type: 'datetime',
        },
        {
          value: 'createdBy', sortable: false, text: 'Ответственный исполнитель продавца', type: 'defaultItem',
        },
        {
          value: 'approvedDate', sortable: false, text: 'Дата подтверждения', type: 'datetime',
        },
        {
          value: 'approvedBy', sortable: false, text: 'Исполнитель Clever', type: 'defaultItem',
        },
        {
          value: 'status', sortable: false, text: 'Статус', type: 'enums',
        },
        {
          text: 'Действие',
          value: 'actions',
          type: 'actions',
          sortable: false,
          child: [
            {
              label: 'Редактировать',
              event: 'edit',
              icon: 'mdi-pencil',
              visible: true,
            },
          ],
        },
      ],
      data: [],
      dialogVisible: false,
      supply: null,
      config: {
        add: false,
        addIcon: false,
        search: false,
        pageable: true,
      },
      totalElements: 0,
      totalPages: 0,
      url: '/api/v1/wms/supplier/requests',
      options: {
        page: 1,
        size: 10,
        sort: 'createdDate,desc',
      },
    };
  },
  created() {
    this.onChangeData(this.$route.query);
  },
  methods: {
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getSupplierRequests();
    },
    getSupplierRequests() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      this.$loading(true);
      this.$api.get(formattedUrl, {
        params: {
          page: params.page - 1,
          size: params.size,
          sort: params.sort,
        },
      }).then((response) => {
        this.totalElements = response.totalElements;
        this.totalPages = response.totalPages;
        this.data = [...response.content].map((item) => ({
          ...item,
          createdBy: item.createdByName,
          approvedBy: item.approvedByName,
        }));
      }).finally(() => {
        this.$loading(false);
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.supply = null;
      this.onChangeData(this.$route.query);
    },
    handleActions(element) {
      this.supply = element.item;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>

</style>
