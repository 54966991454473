<template>
  <v-dialog v-model="visible"
            width="1800"
            @click:outside="closeDialog"
            @keydown.esc="closeDialog">
    <v-card>
      <v-card-title class="card-title elevation-3">
        Заявка на поставку #{{ item.id }} от {{ item.createdDate | format }}
        <v-btn absolute right icon @click="closeDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="mt-5">
        <v-container fluid>
          <v-row>
            <v-col cols="4">
              <v-card class="elevation-2">
                <v-card-text>

                  <v-text-field readonly
                                v-model="item.dates"
                                outlined
                                label="Период доставки"
                                placeholder=""
                                persistent-placeholder
                  />
                  <v-text-field readonly
                                v-model="item.supplier.name"
                                outlined
                                label="Продавец"
                                placeholder=""
                                persistent-placeholder
                  />
                  <v-text-field readonly
                                v-model="item.createdByName"
                                outlined
                                label="Ответственный исполнитель продавца"
                                placeholder=""
                                persistent-placeholder
                  />
                  <v-text-field readonly
                                v-model="item.supplier.email"
                                outlined
                                label="Email"
                                placeholder=""
                                persistent-placeholder
                  />
                  <v-text-field readonly
                                v-model="item.supplier.telephone"
                                outlined
                                label="Контактный телефон"
                                placeholder=""
                                persistent-placeholder
                  />
                  <v-text-field readonly
                                v-model="item.warehouseId"
                                outlined
                                label="Склад Clever"
                                placeholder=""
                                persistent-placeholder
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="8">
              <v-card class="elevation-2 mb-5">
                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :disabled="item.status !== 'CREATED'"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateFormatted"
                          outlined
                          :rules="[v => !!v]"
                          placeholder=""
                          persistent-placeholder
                          label="Выберите дату поставки"
                          readonly
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="item.receptionDate"
                        locale="ru"
                        @change="save"
                        :min="item.startDate"
                        :max="item.endDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-form>
                </v-card-text>
              </v-card>
              <v-card elevation="2">
                <v-card-title>
                  Товары
                </v-card-title>
                <v-card-text>
                  <crm-table :headers="header" :data="items"></crm-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider/>
      <v-card-actions v-if="item.status === 'CREATED'">
        <v-row class="mt-5">
          <v-spacer/>
          <v-col cols="2">
            <v-btn block color="primary" text @click="closeDialog">ОТМЕНИТЬ</v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn block color="primary" rounded
                   @click="approveSupplierRequest"
            >ПРИНЯТЬ
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import crmTable from '@/components/crm-table/index.vue';
import moment from 'moment';

export default {
  name: 'SupplyCard',
  props: {
    productsCount: {
      default: 0,
    },
    visible: {
      default: false,
    },
    id: {
      default: null,
    },
  },
  filters: {
    format(val) {
      if (moment(val).isValid()) {
        return moment(val, 'YYYY-MM-DDTHH:mm:ss.sss[Z]').format('DD-MM-YYYY');
      }
      return '';
    },
  },
  components: {
    'crm-table': crmTable,
  },
  data() {
    return {
      valid: false,
      items: [],
      item: {
        supplier: {
          name: '',
        },
      },
      header: [
        {
          text: '#', value: 'id', align: 'center', type: 'defaultItem',
        },
        {
          text: 'Штрихкод', value: 'barcode', align: 'center', type: 'defaultItem',
        },
        {
          text: 'Наименование', value: 'product.nameRu', align: 'center', type: 'defaultItem',
        },
        {
          text: 'Цвет', value: 'product.color.nameRu', align: 'center', type: 'defaultItem',
        },
        {
          text: 'Размер', value: 'product.size', align: 'center', type: 'defaultItem',
        },
        {
          text: 'Количество', value: 'amount', align: 'center', type: 'defaultItem',
        },
      ],
      data: [],
      menu: null,
      supplyId: null,
    };
  },
  computed: {
    dateFormatted() {
      if (this.item.receptionDate) {
        if (this.$moment(this.item.receptionDate, 'YYYY-MM-DD').isValid()) {
          return this.$moment(this.item.receptionDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
        }
        if (this.$moment(this.item.receptionDate).isValid()) {
          return this.$moment(this.item.receptionDate).format('DD-MM-YYYY');
        }
        return this.$moment(this.item.receptionDate).format('DD-MM-YYYY');
      }
      return '';
    },
  },
  async created() {
    this.supplyId = this.id;
    await this.fetchData();
  },
  methods: {
    closeDialog() {
      this.$emit('closed');
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    async fetchData() {

      this.$loading(true);
      try {
        const res = await this.$api.get(`/api/v1/wms/supplier/requests/${this.supplyId}`)

        this.item = res;

        this.item.dates = `${this.$moment(res.startDate).format('DD-MM-YYYY')} - ${this.$moment(res.endDate).format('DD-MM-YYYY')}`;
        const getSupplierRequestProductItemsById = (id, pageNumber, pageSize) => {
          return this.$api.get(`/api/v1/wms/supplier/requests/${id}/items?page=${pageNumber}&size=${pageSize}`);
        };
        if (this.productsCount > 0) {
          const pageSize = 100;
          // считаем количество страниц которое есть в count
          const pages = Array.from(
            {length: Math.ceil(this.productsCount / pageSize)},
            (_, i) => i
          );
          const requests =
            (await Promise.all(
              pages.map(pageNumber => {
                console.log(pageNumber, 'pageNumber')
                return getSupplierRequestProductItemsById(
                  this.supplyId,
                  pageNumber,
                  pageSize
                );
              })
            )) || [];

          this.items = requests
            ?.map(prom => prom.content)
            ?.flat(1).sort((a, b) => a.id - b.id);

          this.$loading(false);

        }
      } catch (e) {
        await this.$router.replace({
          name: 'supplier-deliveries',
        });
      }

    },
    approveSupplierRequest() {
      if (this.$refs.form.validate()) {
        let date;
        if (this.$moment(this.item.receptionDate, 'YYYY-MM-DD').isValid()) {
          date = this.$moment(this.item.receptionDate, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss.sss[Z]');
        } else if (this.$moment(this.item.receptionDate).isValid()) {
          date = this.$moment(this.item.receptionDate, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss.sss[Z]');
        } else if (this.$moment(this.item.receptionDate).isValid()) {
          date = this.$moment(this.item.receptionDate).format('DD-MM-YYYY');
        }
        const body = {
          id: this.supplyId,
          receptionDate: date,
        };
        this.$api.post('/api/v1/wms/supplier/requests/approve', body).then(() => {
          this.closeDialog();
        });
      } else {
        setTimeout(() => {
          this.$refs.form.resetValidation();
        }, 5000);
      }
    },
  },
};
</script>

<style lang="scss">

.action-header {
  &__save {
    border: 5px solid #9DC033;
    border-radius: 3px;
    color: #9DC033;

    span {
      color: #9DC033;
    }
  }

  span {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 33px;
    /* identical to box height */

    text-align: center;

  }

  &__submit {
    span {
      color: #FFFFFF;
    }
  }
}

.main-body {
  margin-top: 30px !important;
  border: 1px solid #BFC1B8;
  min-height: 85vh;
  border-radius: 3px;

}

.title {
  border-bottom: 1px solid #BFC1B8;
  height: 50px;
}

.order {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  color: #7E8271;
}

.number {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #7E8271;
}

.status {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #00D1FF;
  border: 3px solid #00D1FF;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  min-width: 117px;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;

  &__icon {
    border-radius: 50%;
    width: 14px;
    height: 14px;
    top: 50%;
    background-color: #FFFFFF;
    margin-right: 10px;
  }
}

.rate-text {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #7E8271;
}

.rate-number {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  vertical-align: center;
  color: #7E8271;

}

.comment-title {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  color: #7E8271;
}

.label {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #7E8271;
}

.order-info {
  b {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */

    color: #7E8271;
  }
}

.product {

  &__title {
    background: #FFFFFF;
    border: 1px solid #BFC1B8;
    border-radius: 3px 3px 0px 0px;
    padding-left: 20px;
    height: 50px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 50px;
    /* identical to box height */

    /* dark green */

    color: #184347;

    span {
      padding: 15px
    }

  }

  &__body {
    min-height: 400px;
    border: 1px solid #BFC1B8;
    border-radius: 3px 3px 0px 0px;
    background-color: #FFFFFF;
  }
}

.product-table {
  margin: 20px;
  border: 1px solid #EFEFED;
  border-radius: 3px 3px 10px 10px !important;
}

</style>
